import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getAquaReports = createAsyncThunk('getAquaReports', async (data) => {
  console.log(data)
  let searchString = `?page=${data.page}&show_uniq=${data.showUnique}`;
  for(const key of Object.keys(data.searchParams)){
    if(data.searchParams[key].length !== 0){
      searchString = searchString + `&q[${key}]=${data.searchParams[key]}`
    }
  }

  const reports = await fetch(`${process.env.REACT_APP_API_URL}/aquareports${searchString}`, {    
    method: 'GET',
    headers: {"Content-Type": "application/json", token: data.token}
  })

  return( reports.json())
})

export const aquaReportsSlice = createSlice({
  name: 'aquaReports',
  initialState: {
    loading: false,
    data: {},
    error: null
  },
  reducers: {},
  extraReducers: (builder) =>{
    builder.addCase(getAquaReports.fulfilled, (state, action) => {
      state.data = action.payload
    });
  }
})

export default aquaReportsSlice.reducer