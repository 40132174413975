export const english = {

  // navigation
  'overview': 'Overview',
  'reports': 'Reports',
  'Aqua Report': 'Aqua Report',
  'WES reports': 'WES reports',
  'Cell reports': 'Cell reports',
  'Dashboard': 'Dashboard',
  'Batteries': 'Batteries',
  'BMS models': 'BMS models', 
  'Override Codes': 'Override Codes',
  'Batteries': 'Batteries',
  'Battery Params': 'Battery Params',
  'Battery Firwares': 'Battery Firwares',
  'Upgrade Manifest': 'Upgrade Manifest',
  'RMA': 'RMA',
  'Users': 'Users',
  'Companies': 'Companies',
  'Chargers': 'Chargers',
  'Settings': 'Settings',
  'Update profile': 'Update profile',
  'New Customer/Supplier': 'New Customer/Supplier',
  '4g reports': '4g Reports',

  // general
  'cancel': 'Cancel',
  'save': 'Save',
  'select': 'Select',
  'note': 'Note',
  'notes': 'Notes',
  'page size': 'Page size',
  'file': 'File',
  'files': 'Files',
  'add file': 'Add file',
  'documents': 'Documents',
  'add document': 'Add document',
  'type': 'Type',
  'docs': 'Docs',
  'model': 'Model',
  'search': 'Search',
  'clear': 'Clear',
  'results': 'Results',
  'id': 'Id',
  'name': 'Name',
  'date': 'Date',
  'dates': 'Dates',
  'created at': 'Created at',
  'updated at': 'Updated at',
  'test date': 'Test date',
  'prod date': 'Prod. date',
  'select': 'Select',
  'actions': 'Actions',
  'excel': 'Excel',
  'delete': 'Delete',
  'commercial invoice': 'Commercial invoice',

  //Component
  'wst number': 'Wst PO #',
  'haidi number': 'Haidi PI #',
  'item number': 'Item #/model #',
  'tracking': 'Tracking #',
  'placed': 'Placed',
  'all done': 'All done',
  'technical document delivered': 'Tdd',
  'purchase order lines': 'PO lines',
  'cusotmer': 'Customer',
  'po line id': 'PO line id',
  'product number': 'Product number',
  'amount': 'Amount',
  'qspec': 'Qspec',
  'pid': 'Pid',
  'td': 'TD',
  'produced': 'Produced',
  'shipped': 'Shipped',
  'actualy done': 'Act. done',
  'expected done': 'Exp. done',
  'cqr': 'CQR',
  'shipments': 'Shipments',
  'all done date': 'All done date', 
  'dest': 'Dest.',
  'qty': 'Qty.',
  'bms model': 'BMS model',
  'io': 'IO',
  'firmware': 'Firmware', 
  'serial number': 'Serial number',
  'cell voltage diff': 'Cell voltage diff.',
  'fc diff': 'Fc diff.',
  'ir': 'IR',
  'csv': 'CSV',
  'show report': 'Show report',
  'po': 'PO',
  'line': 'Line',
  'soc': 'SOC',
  'mos temp': 'Mos temp.',
  'ambient temp': 'Ambient temp.',
  'cell t max': 'Cell T. max.',
  'cell t min': 'Cell T. min.',
  'design cap': 'Design cap.', 
  'full cap': 'Full cap.', 
  'rem cap': 'Rem cap.',
  'cell v max': 'Cell v. max.',
  'cell v min': 'Cell v. min.',
  'cell diff': 'Cell diff.',
  'fc diff': 'FC diff.', 
  'aqua report id': 'Aqua report id',
  'aqua report': 'Aqua report',
  'battery model': 'Battery model',
  'internal note': 'Internal note',
  'customer note': 'Customer note',
  'bill of lading': 'Bill of lading',
  'packing list': 'Packing list',
  'packing details': 'Packing details',
  'chg': 'CHG.',
  'dsg': 'DSG. ',
  'ov number': 'OV #',
  'uv number': 'UV #',
  'sc number': 'SC #',
  'ov cycle ratio': 'OV cycle ratio',
  'uv cycle ratio': 'UV cycle ratio',
  'sc cycle ratio': 'SC cycle ratio',
  'charging': 'Charging',
  'checked by': 'Checked by',
  'delivery date': 'Delivery date',
  'rma type': 'Rma type',
  'customer rma number': 'Customer RMA #',
  'supplier rma number': 'Supplier RMA #',
  'customer issue': 'Customer issue',
  'status': 'Status',
  'fault': 'Fault',
  'details': 'Details',
  'battery': 'Battery',
  'charger': 'Charger',
  'created': 'Created',
  'received': 'Received',
  'working': 'Working',
  'cycle number':  'Cycle #',
  'upgrades': 'Upgrades',
  'invalid credentials': 'Invalid credentials',


  //Modal
  'show only open orders': 'Show only open orders',
  'open all fields': 'Open all fields',
  'new purchase order': 'New purchase order',
  'select cusotmer': 'Select customer',
  'purchase order line id': 'Purchase order line id',
  'aqua enabled': 'Aqua enabled',
  'wes enabled': 'WES enabled',
  'internal order for pack': 'Internal order for pack',
  'expected production done date': 'Expected production done date',
  'amount complete': 'Amount completed',
  'serial number begins': 'Serial number begins',
  'serial number end': 'Serial number end',
  'cell quality report': 'Cell quality report',
  'technical documents': 'Technical documents',
  'show old qspec': 'Show old qspec',
  'show old wes spec': 'Show old WES spec',
  'wes spec': 'WES spec',
  'internal order': 'Internal order',
  'auto update eta': 'Auto update ETA',
  'comercial invoice': 'Comercial invoice',
  'wst order number': 'Wst order number',
  'haidi order number': 'Haidi order number',
  'customer order number': 'Customer order number',
  'purchase order line': 'Purchase order line', 
  'destination country': 'Destination country',
  'method': 'Method',
  'amount shipped': 'Amount shipped',
  'override ship name': 'Override ship name',
  'forced imo to use for vessel finder': 'Forced imo to use for vessel finder',
  'shipped date': 'Shipped date',
  'expected delivery date': 'Expected delivery date',
  'recieved date': 'Recieved date',
  'packvoltage': 'Pack voltage',
  'less than or equal': 'Less than or equal',
  'more than or equal': 'More than or equal',
  'bms serial number': 'BMS serial number',
  'create bms model': 'Create bms model',
  'barcode': 'Barcode',
  'manufacturer': 'Manufacturer',
  'voltage': 'Voltage',
  'charger serial': 'Charger serial',
  'charger model': 'Charger model',
  'new charger': 'New charger',
  'select charger model': 'Select charger model',
  'company name': 'Company name', 
  'update company': 'Update company',
  'create company': 'Create company',
  'customer name': 'Customer name',
  'user': 'User',
  'token': 'Token',
  'code': 'Code',
  'create override code': 'Create override code',
  'comm.': 'Comm.',
  'casing voltage': 'Casing voltage',
  'true': 'True',
  'false': 'False',
  'root cause analysis': 'Root cause analysis',
  'warranty wst status': 'Warranty wst status',
  'replacement percentage': 'Replacement %',
  'warranty supplier status': 'Warranty supplier status',
  'replacement spent': 'Replacement spent',
  'supplier': 'Supplier',
  'new rma': 'New rma',
  'update rma': 'Update rma',
  'wst rma number': 'Wst RMA #',
  'completed date': 'Completed date',
  'supervisor help': 'Supervisor help',
  'waiting on supplier': 'Waiting on supplier',
  'processed': 'Processed',
  'discarded': 'Discarded',
  'returned': 'Returned',
  'customer Rma': 'Customer Rma',
  'wst rma': 'Wst Rma',
  'supplier rma': 'Supplier Rma',
  'received date': 'Received date',
  'warranty length': 'Warranty length',
  'wst warranty': 'Customer claim status',
  'unknown': 'Unknown',
  'no claim': 'No Claim',
  'pending': 'Pending',
  'accepted': 'Accepted',
  'rejected': 'Rejected', 
  'yes': 'Yes',
  'no': 'No',
  'supplier warranty': 'Supplier claim status',
  'fault description': 'Fault description',
  'select fault description': 'Select Fault description',
  'assembly error': 'Assembly error',
  'deep discharged': 'Deep discharged',
  'bms issue': 'BMS Issue',
  'no issue': 'No Issue',
  'bricked': 'Bricked',
  'parameter issue': 'Parameter Issue',
  'charger issue': 'Charger Issue',
  'cell issue': 'Cell Issue',
  'handling error': 'Handling error',
  'na': 'N/A',
  'calculate warranty': 'Calculate warranty',
  'email': 'Email',
  'password': 'Password',
  'login': 'Log in',
  'create firmware': 'Create firmware',
  'new battery firmware': 'New Battery Firmware',
  'select model': 'Select model',
  'parameters': 'Parameters',
  'create parameter': 'Create parameter',
  'new battery params': 'New battery params',
  'parameters data': 'Parameters data',
  'battery parameter': 'Battery parameter',
  'battery firmware': 'Battery firmware',
  'new upgrade manifest': 'New Upgrade Manifest',
  'select firmware': 'Select firmware',
  'battery params': 'Battery params',
  'select param': 'Select param',
  'company': 'Company',
  'role': 'Role',
  'create user': 'Create user',
  'password confirmation': 'Password confirmation',
  'select company': 'Select company',
  'report version': 'Report version',
  'test type': 'Test type',
  'delete confirmation': 'Delete confirmation',
  'version': 'Version',
  'description': 'Description',
  'mandatory': 'Mandatory',
  'allow downgrade': 'Allow downgrade',
  'update shipment': 'Update shipment',
  'create shipment': 'Create shipment',
  'create customer': 'Create customer',

  // Messages
  'are you sure you want to delete purchase order': 'Are you sure you want to delete Purchace Order',
  'purchase order line id not filled in': 'PO line id not filled in',
  'product number too short': 'Product number is too short (minimum is 4 characters) and equal an existing product',
  'amount not set': 'Amount not set',
  'bill of lading missing': '(Bill of lading missing)',
  'expected done date not set': '(Expected done date not set / 实际完成日期未设定)',
  'not shipped on time': '(Not shipped on time/ 未按时发货)',
  'not produced on time': '(Not produced on time / 未按时生产)',
  'are you sure you want to delete purchase order line': 'are you sure you want to delete Purchace Order Line',
  'wst order number cannot be empty and has to be unique': 'Wst Order Number cannot be empty and has to be unique',
  'amount shipped must be bigger than zero': 'Amount shipped must be bigger than 0',
  'ammount shipped must be less than one million': 'Amount shipped must be less than 1000000',
  'total shipped amount cannot exceed the ordered amount': 'Total shipped amount cannot exceed the ordered amount',  
  'are you sure you want to delete shipment': 'Are you sure you want to delete shipment',
  'rma successfully saved': 'Rma successfully saved',
  'rma successfully deleted': 'Rma successfully deleted',
  'assign selected manifest to selected batteries': 'Assign selected manifest to selected batteries',
  'assign Manifest to batteries': 'Assign Manifest to batteries',
  'remove selected upgrade from selected batteries': 'Remove selected upgrade from selected batteries',
  'type cannot be blank': 'Type cannot be blank',
  'status cannot be blank': 'Status cannot be blank',
  'recieved date cannot be blank': 'Recieved date cannot be blank',
  'wst rma number cannot be blank': 'Wst rma number cannot be blank',
  'wst rma numer allready exists': 'Wst Rma numer allready exists',
  'serial number cannot be blank': 'Serial number cannot be blank',
  'model cannot be blank': 'Model cannot be blank',
  'customer or supplier allready exists': 'Customer or supplier allready exists',
  
  
  'bms parameters': 'BMS parmeters', 
  'ov threshold': 'OV Threshold (V):',
  'ov recovery': 'OV Recovery (V):',
  'ov delay': 'OV Delay (ms):',
  'ov recovery delay': 'OV Recovery dly (S):',
  
  'uv threshold': 'UV Threshold (V):',
  'uv recovery': 'UV Recovery (V):',
  'uv delay': 'UV Delay (ms):',
  'uv recovery delay': 'UV Recovery delay (S):',

  'occ threshold': 'OCC threshold (A):',
  'occ delay': 'OCC Delay (S):',
  
  'occ2 threshold': 'OCC2 Threshold (A):',
  'occ2 delay': 'OCC2 Delay (ms):',

  'ocd1 threshold': 'OCD1 Threshold (A):',
  'ocd1 delay': 'OCD1 Delay (S):',
  'ocd2 threshold': 'OCD2 Threshold (A):',
  'ocd2 delay': 'OCD2 Delay (ms):',
  
  'sc threshold': 'SC threshold (A):',
  'sc delay': 'SC Delay (ms):',
  
  'balv threshold': 'BALV threshold (V):',
  'balv delta': 'BALV Delta (mV):',

  'ot': 'OT (C):',
  'ot recovery': 'OT Recovery (C):',
  'ut': 'UT (C):',
  'ut recovery': 'UT Recovery (C):',

  'static balance': 'Static Balance',
  'balance time': 'Balance Time (min):',

  'total voltage protect': 'Total Voltage Protect',
  'tov threshold': 'TOV Threshold (V):',
  'tov recovery': 'TOV Recovery (V):',
  'tov delay': 'TOV delay (ms):',
  'tov recovery delay': 'TOV Recovery Dly (S):',

  'tuv threshold': 'TUV Threshold (V):',
  'tuv recovery': 'TUV Recovery (V):',
  'tuv delay': 'TUV delay (ms):',
  'tuv recovery delay': 'TUV Recovery Dly (S):',

  'occ recovery': 'OCC Recovery',
  'occ recovery auto recovery delay': 'Auto Recovery Dly (min):',
  'occ recovery auto recovery lock': 'Auto Recovery Lock (n):',

  'sc recovery': 'OCD/SC Recovery',
  'sc auto recovery delay': 'Auto Recovery Dly(min):',
  'sc auto recovery lock': 'Auto Recovery Lock (n):',

  'charge current limiting': 'Charge Current Limiting',
  'charge current': 'Current (A):',

  'alarm parameter': 'Alarm Parameter',
  'voltage alarm': 'Voltage Alarm',

  'pack voltage high': 'Pack Voltage High (V):',
  'pack voltage low': 'Pack Voltage Low (V):',
  'cell voltage high': 'Cell Voltage High (V):',
  'cell voltage low': 'Cell Voltage Low (V): ',

  'current alarm': 'Current Alarm',

  'chg threshold': 'CHG Threshold (A):',
  'dsg threshold': 'DSG Threshold (A):',

  'cell temp alarm': 'Cell Temp Alarm',
  
  'cell temp otc': 'OTC (C):',
  'cell temp utc': 'UTC (C):',

  'ambient temp alarm': 'Ambient Temp Alarm',

  'ambient temp otc': 'OTC (C):',
  'ambient temp utc': 'UTC (C):',

  'mos temp alarm': 'MOS Temp Alarm',

  'mos temp otc': 'OTC (C):',
  'mos temp ot recovery': 'OT Recovery (C):',


  'soc alarm': 'SOC Alarm',

  'soc low threshold': 'SOC Low Threshold (%):',

  'shut down voltage': 'Shut Down Voltage (V):', 
  'shut down delay': 'Shut Down Delay (min):', 
  'shut down current': 'Shut Down Current (A):', 
  'mos otp': 'MOS OTP (C)', 
  'mos otp recovery': 'MOS OTP Recovery (C):', 
  'mos ot delay': 'MOS OT Delay (C):',
	'ambient otp': 'Ambien OTP (C):', 
  'ambient otp recovery': 'Ambient OTP Recovery (C):', 
  'ambient utp': 'Ambient UTP (C):', 
  'ambient utp recovery': 'Ambient UTP Recovery (C):',

  'self dsg rate': 'Self DSG Rate (%):', 
  'cycle cap': 'Cycle cap (%):', 
  'soc 0 voltage': 'SOC 0% Voltage (V):',

  'pack full charge voltage': 'Pack Full Charge Voltage (V):', 
  'pack full charge current': 'Pack Full Charge Current (A):', 
  'full charge delay': 'Full Charge Delay (min):',
  'Ip uid': 'Ip uid',

  'cells': 'Cells',
  'create cell': 'Create cell',
  'model number': 'Model number', 
  'brand': 'Brand', 
  'chemestry': 'Chemistry ', 
  'max voltage': 'Max voltage (V)', 
  'min voltage': 'Min voltage (V)', 
  'capacity': 'Capacity (Ah)', 
  'charge rate': 'Charge rate (C)', 
  'min discharge rate': 'Min discharge rate (C)', 
  'max discharge rate': 'Max discharge rate (C)',
  'model_number': 'Model number', 
  'brand': 'Brand', 
  'chemestry': 'Chemistry', 
  'max_voltage': 'Max voltage (V)', 
  'min_voltage': 'Min voltage (V)', 
  'capacity': 'Capacity (Ah)', 
  'charge_rate': 'Charge rate (C)', 
  'min_discharge_rate': 'Min discharge rate (C)', 
  'max_discharge_rate': 'Max discharge rate (C)',
  'diameter': 'Diameter (mm)', 
  'lenght': 'Length (mm)', 
  'thickness': 'Thickness (mm)', 
  'height': 'Height (mm)', 
  'energy_density': 'Energy density (Wh/Kg)',
  'energy density': 'Energy density (Wh/Kg)',
  'weight': 'Weight (kg)',
  'energy': 'Energy (Wh)',
  'ocv table': 'OCV table',
  'datasheet': 'Datasheet',
  'acir': 'ACIR (mΩ)',
  "series": "Series", 
  "wst legacy name": "Wst legacy name", 
  "wst name": "Wst name", 
  "continuous discharge current": "Continuous discharge current", 
  "continuous charge current": "Continuous charge current", 
  "peak discharge current": "Peak discharge current", 
  "communication": "Communication",
  "wst_legacy_name": "Wst legacy name", 
  "wst_name": "Wst name", 
  "continuous_discharge_current": "Continuous discharge current", 
  "continuous_charge_current": "Continuous charge current", 
  "peak_discharge_current": "Peak discharge current", 
  "Cell quality report not uploaded": 'Cell quality report not uploaded (未上传细胞质量报告)',
  'other': 'Other',
  'show only unique': 'Show only unique'
}